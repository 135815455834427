import React from "react"
import {
  Input,
  InputAdornment,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Skeleton,
} from "@mui/material"
import Copy from "./Copy"

function Modal({ open, handleClose, url, loading }) {
  return (
    <div>
      <Dialog
        open={loading || open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {loading ? "Almost done" : "Your short URL"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {loading ? (
              <Skeleton variant="rectangular" width={200} height={32} />
            ) : (
              <Input
                value={url}
                endAdornment={
                  <InputAdornment position="end">
                    <Copy text={url} />
                  </InputAdornment>
                }
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading} autoFocus>
            Shorten new URL
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Modal
