import React from "react"
import { withStyles } from "@mui/styles"

import logo from "../svg/logo.svg"

const styles = (theme) => ({
  logo: {
    height: 50,
  },
  container: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    padding: 5,
    paddingLeft: 20,
    borderBottom: `5px solid ${theme.palette.primary.main}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerBorder: {
    backgroundColor: theme.palette.primary.light,
    height: 5,
    boxShadow: `5px 5px 15px -12px ${theme.palette.secondary.main}`,
  },
  logoText: {
    fontSize: 22,
    alignSelf: "center",
    paddingLeft: 5,
    paddingRight: 5,
  },
  logoContainer: {
    display: "flex",
  },
  description: {
    fontSize: 24,
    paddingRight: 20,
    textAlign: "right",
  },
})

function Header({ classes }) {
  const domain = process.env.REACT_APP_SITE_DOMAIN
  return (
    <>
      <div className={classes.container}>
        <div className={classes.logoContainer}>
          <img src={logo} className={classes.logo} alt="logo" />
          <div className={classes.logoText}>{domain}</div>
        </div>
        <div className={classes.description}>URL shortening service</div>
      </div>
      <div className={classes.headerBorder} />
    </>
  )
}

export default withStyles(styles)(Header)
