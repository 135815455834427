import React from "react"
import { withStyles } from "@mui/styles"
import Header from "./Header"
import ShortenerForm from "./ShortenerForm/ShortenerForm"

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
  },
  formContainer: {
    flexGrow: 0.5,
  },
  copy: {
    textAlign: "center",
    color: theme.palette.primary.light,
    marginBottom: "1rem",
  },
})

function MainPage({ classes }) {
  return (
    <div className={classes.root}>
      <div>
        <Header />
      </div>
      <div className={classes.formContainer}>
        <ShortenerForm />
      </div>
      <div className={classes.copy}>
        &copy; {process.env.REACT_APP_SITE_DOMAIN} 2022
      </div>
    </div>
  )
}

export default withStyles(styles)(MainPage)
