import React, { useState } from "react"
import { withStyles } from "@mui/styles"
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material"
import Modal from "../Modal"

const styles = (theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    border: "1px solid #ccc",
    margin: "0 5rem",
    boxShadow: `0px 6px 20px -1px ${theme.palette.primary.light}`,
  },
  error: {
    color: "#ff0000",
    fontSize: "0.7rem",
    marginLeft: "0.7rem",
  },
  submit: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  fullWidth: {
    width: "100%",
    paddingRight: 15,
  },
})

function ShortenerForm({ classes }) {
  const [url, setUrl] = useState("")
  const [result, setResult] = useState("")
  const [valid, setValid] = useState(true)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const showModal = () => {
    setOpen(true)
  }

  const createUrl = (e) => {
    e.preventDefault()
    setLoading(true)
    showModal()
    const postUrl = `https://${process.env.REACT_APP_SITE_DOMAIN}/create`
    fetch(postUrl, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url }),
    }).then((response) => {
      response.json().then((body) => {
        setResult(body.url)
        setLoading(false)
      })
    })
  }

  const handleClose = () => {
    setUrl("")
    setOpen(false)
  }
  const handleChange = ({ target }) => {
    let { value } = target
    if (!/^https?:\/\//.test(value)) {
      if (/^[a-zA-Z]+\./.test(value)) {
        value = `http://${value}`
        setValid(true)
      } else {
        setValid(false)
      }
    } else {
      setValid(true)
    }
    setUrl(value)
  }
  return (
    <div className={classes.root}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <form onSubmit={createUrl} className={classes.fullWidth}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="url-input">URL</InputLabel>
            <OutlinedInput
              id="url-input"
              error={!valid}
              value={url}
              onChange={handleChange}
              autoComplete="off"
              label="URL"
            />
            <div className={classes.error}>{!valid && "Wrong URL format"}</div>
            <Button
              className={classes.submit}
              type="submit"
              disabled={!valid || !url}
            >
              Shorten
            </Button>
          </FormControl>
        </form>
      </Box>
      <Modal
        open={open}
        handleClose={handleClose}
        loading={loading}
        url={result}
      />
    </div>
  )
}

export default withStyles(styles)(ShortenerForm)
