import React, { useState } from "react"
import { CopyAll, Check } from "@mui/icons-material"

function Copy({ text }) {
  const [copied, setCopied] = useState(false)
  return copied ? (
    <Check style={{ cursor: "auto" }} color="success" />
  ) : (
    <CopyAll
      style={{ cursor: "pointer" }}
      onClick={() => {
        navigator.clipboard.writeText(text).then(() => setCopied(true))
      }}
    />
  )
}

export default Copy
