import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    primary: {
      light: "#718792",
      main: "#455a64",
      dark: "#1c313a",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#f3f9c5",
      main: "#c0c694",
      dark: "#8f9566",
      contrastText: "#4f4f4f",
    },
  },
})

export default theme
